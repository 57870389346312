import { getVideoAppSettings, saveVideoAppSettings } from '@/api/authority-manage';

import { Button, Input } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';

export default {
  name: 'VideoApplication',
  props: {},
  data() {
    return {
      address: '',
      isEdit: false,
    };
  },
  components: {
    backStageHeader,
    [Button.name]: Button,
    [Input.name]: Input,
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getVideoAppSettings().then((res) => {
        if (res && res.result) {
          const url = (this.address = res.result.videoPlatformUrl || '');
          if (!url) {
            this.isEdit = true;
          }
        }
      });
    },
    handleSave() {
      if (!this.isEdit) {
        this.isEdit = true;
      } else {
        saveVideoAppSettings({
          videoPlatformUrl: this.address,
        }).then((res) => {
          if (res && res.status == 0) {
            this.$message.success('保存成功');
            if (this.address) {
              this.isEdit = false;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
