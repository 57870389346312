/**
 * @file authority-manage模块接口
 */
import request from '@/utils/request';

/** 权限管理-视频应用 */
/**
 * @desc 获取视频应用平台地址
 */
export const getVideoAppSettings = (data) => {
  return request({
    url: '/getVideoAppSettings.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 保存视频应用平台地址
 * @param { String } videoPlatformUrl 视频应用平台接入地址，目前pc端修改需要传入
 * @param { String } courseMemoirUrl 学生访问课堂实录地址，目前pc端修改不需要传入，可能被取消，暂时保留这个参数
 */
export const saveVideoAppSettings = (data) => {
  return request({
    url: '/saveVideoAppSettings.do',
    method: 'GET',
    data,
  });
};

/** 权限管理-校本资源 */
/**
 * @desc 获取校本资源平台地址
 */
export const getSchoolResourceAddress = (data) => {
  return request({
    url: '/userSetting/getAddress.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 保存校本资源平台地址（用于新增或者更改）
 * @param { String } serviceUrl 地址
 */
export const saveSchoolResourceAddress = (data) => {
  return request({
    url: '/userSetting/saveAddress.do',
    method: 'POST',
    data,
  });
};

/** 权限管理-资源接入 */
/**
 * @desc 获取校本资源平台地址
 * @param { Number } pageIndex 当前页
 * @param { Number } pageSize 每页大小
 * @param { String } serverName 资源名称，搜索框的内容
 */
export const getThirdresourceserver = (data) => {
  return request({
    url: '/thirdresourceserver/page/json.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 移除第三方资源
 * @param { String } id 主键id
 */
export const removeThirdresourceserver = (data) => {
  return request({
    url: '/thirdresourceserver/remove.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 保存接入第三方资源访问地址
 * @param { String } serverAddress 地址
 * @param { String } serverName 名称
 * @param { String } id id,修改时必传
 */
export const saveThirdresourceserver = (data) => {
  return request({
    url: '/thirdresourceserver/save.do',
    method: 'POST',
    data,
  });
};

/** 权限管理-空中课堂 */

/**
 * @desc 获取空中课堂地址
 */
export const getCrossCourseAddress = () => {
  return request({
    url: '/crossCourseAddress/detail.do',
    method: 'POST',
  });
};

/**
 * @desc 结对组列表
 * @param { Number } pageSize 每页大小
 * @param { String } pageIndex 当前页
 */
export const getListPairGroup = (data) => {
  return request({
    url: '/base/listPairGroup.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 选择听讲学校列表
 * @param { String } schoolName 学校名称
 */
export const getListSchool = (data) => {
  return request({
    url: '/base/listSchool.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 选择主讲教室或者听讲教室列表
 * @param { Number } schoolId 学校id
 */
export const getListClassrom = (data) => {
  return request({
    url: '/base/listClassrom.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 移除结对组
 * @param { Number } pairGroupId 结对组id
 */
export const deletePairGroup = (data) => {
  return request({
    url: '/base/deletePairGroup.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 保存结对组信息
 * @param { Number } classRoomId 主讲教室id
 * @param { String } lectureRooms 听讲教室：[{"classRoomId":"700000000000001","schoolId":"700000000000001"},{"classRoomId":"700000000000002","schoolId":"700000000000001"},{"classRoomId":"800000000000001","schoolId":"800000000000001"}]
 * @param { Number } pairGroupId 结对组ID(不传时为新增结对组,有值为修改结对组)
 */
export const saveOrUpdatePairGroup = (data) => {
  return request({
    url: '/base/saveOrUpdatePairGroup.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 保存空中课堂配置
 * @param { Number } type 类型(0:没有空中课堂;1:靑鹿临时开课;2:接入ava专递课堂)
 * @param { String } remoteUrl 地址
 */
export const saveCrossCourseAddress = (data) => {
  return request({
    url: '/save/crossCourseAddress.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 查询学校的空中课堂课表列表
 * @param { String } startTime 开始日期，约定传当前周的周一，yyyy-MM-dd HH:mm:ss，不传的话， 查询所有数据。开始结束就是一周的时间范围，可以是周日到周一， 也可以是周一到周日，取决于前端显示的日期样式
 * @param { String } endTime 结束日期，约定传当前周的周日，yyyy-MM-dd HH:mm:ss，不传的话， 查询所有数据。开始结束就是一周的时间范围，可以是周日到周一， 也可以是周一到周日，取决于前端显示的日期样式
 */
export const getCourseListGroupByDate = (data) => {
  return request({
    url: '/cross/course/list/groupByDate/json.do',
    method: 'GET',
    data,
  });
};
